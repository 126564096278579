<script setup lang="ts">
import type { VoixMediaFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    media1: VoixMediaFieldInterface
    media2: VoixMediaFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Bento Merging Circles', group: 'Bento', layouts: ['VoixMarketing'] },
  slots: [{ name: 'default', allowedElements: [] }],
  description: '',
  preview: 'SlicesBentoMergingCircles.jpg',
  fields: {
    media1: {
      type: 'media',
      label: 'Media 1',
      breakpoints: {
        default: {
          width: 320,
          height: 320,
        },
      },
    },
    media2: {
      type: 'media',
      label: 'Media 2',
      breakpoints: {
        default: {
          width: 320,
          height: 320,
        },
      },
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const activated = inject('activated')
</script>

<template>
  <div class="w-full h-full flex">
    <div class="relative group w-full">
      <div class="flex justify-center items-center h-full duration-1000 delay-200" :class="{ 'grayscale opacity-20': !activated }">
        <div
          class="relative w-2/5 translate-x-[10%] group-hover:translate-x-[20%] delay-400 duration-1000  -translate-y-[20%]"
        >
          <div
            class="relative rounded-full aspect-square border-4 overflow-hidden border-voix-middle bg-voix-middle/10 flex items-center justify-center"
          >
            <div class="absolute z-10 inset-0">
              <VoixMedia :field="fields.media1" class="w-full h-full object-cover" />
            </div>
          </div>
        </div>
        <div
          class="relative w-2/5 -translate-x-[10%] group-hover:-translate-x-[20%]  delay-400 duration-1000 -translate-y-[20%]"
        >
          <div
            class="relative inset-0 mix-blend-overlay overflow-hidden rounded-full aspect-square border-4 border-voix-stroke bg-voix-stroke/10 flex items-center justify-center"
          >
            <div class="absolute z-10 inset-0">
              <VoixMedia :field="fields.media2" class="w-full h-full object-cover" />
            </div>
            <div class="absolute inset-0 overflow-hidden rounded-full">
              <div
                class="absolute inset-0 overflow-hidden circle-2 -translate-x-[81%] group-hover:-translate-x-[61%] duration-300 rounded-full aspect-square flex items-center justify-center"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
